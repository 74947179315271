import { TranslationDictionary } from '@sprinx/react-globalize/types';
import isObjectLike from 'lodash/isObjectLike';
import React from 'react';
import {
  ProductDisplayParametersItem,
  ProductParametersParameterValue,
  ProductParameterTypeEnum,
  ProductParameterTypes,
  ProductPopulates,
} from '../../@sprinx/knihovka-types';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export function convertProductParametersToDisplay(
  locale: string,
  productParameterTypes: undefined | ProductParameterTypes,
  parameters: ProductPopulates['parameters']['parameters'],
  t: ReturnType<typeof useTranslateWithFallback>,
): undefined | ProductPopulates['parametersDisplay']['parameters'] {
  if (!parameters || !productParameterTypes) return undefined;

  function resolveParamDisplayValue(
    productTypeEnum: ProductParameterTypeEnum[] | undefined,
    parameterValue: ProductParametersParameterValue | undefined,
  ): string | number | boolean | undefined {
    if (parameterValue === undefined) return undefined;

    // Parameter value is TranslationDictionary
    if (Array.isArray(parameterValue)) return t(parameterValue, { acceptString: true });

    // dont lookup for value in enum
    if (!productTypeEnum) {
      return isObjectLike(parameterValue)
        ? t((parameterValue as { label: TranslationDictionary }).label, { acceptString: true })
        : (parameterValue as string | boolean | number);
    }

    const enumValue = productTypeEnum.find((it: ProductParameterTypeEnum) =>
      Array.isArray(it) ? it[0] === parameterValue : it === parameterValue,
    );

    if (typeof enumValue === 'number') return enumValue;
    if (typeof enumValue === 'string') return enumValue;
    if (Array.isArray(enumValue)) return t((enumValue as [any, TranslationDictionary])[1], { acceptString: true });

    return undefined;
  }

  const displayParameters = parameters
    .map((productParam) => {
      const type = productParameterTypes.find((pt) => pt.code === productParam.code);

      if (type === undefined) return undefined;

      // Lookup for display value
      const parameterDisplayValue = resolveParamDisplayValue(type.enum, productParam.value);

      return {
        ...productParam,
        value: parameterDisplayValue,
        label: t(type.label, { acceptString: true }),
      };
    })
    .filter(Boolean) as ProductDisplayParametersItem[];

  return displayParameters;
}

export default function useConvertProductParametersToDisplay(
  locale: string,
): (
  productParameterTypes: undefined | ProductParameterTypes,
  parameters: undefined | ProductPopulates['parameters']['parameters'],
) => undefined | ProductPopulates['parametersDisplay']['parameters'] {
  const t = useTranslateWithFallback();

  return React.useCallback(
    (productParameterTypes, parameters) =>
      convertProductParametersToDisplay(locale, productParameterTypes, parameters, t),
    [locale, t],
  );
}
